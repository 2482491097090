import React, { Fragment } from 'react'
import rightArrow from '../../assets/images/icons/arrow-right.svg'

export default function TrackerAction({ tracker }) {
  return (
    <div className='tracker__action'>
      <div className='tracker__action-paragraph'>
        <h2 className="ics__h2">{tracker.action.title}</h2>
        <p className="ics__p">{tracker.action.subtitle}</p>
      </div>
      <ul className='tracker__action-points'>
        {tracker.action.points.map((point, index, pointsArr) => (
          <Fragment key={point.id}>
            <li className="ics__p-pullout">{point.text}</li>
            {index < pointsArr.length - 1 && <img src={rightArrow} alt='right arrow' className='tracker__action-img' />}
          </Fragment>
        ))}
      </ul>
    </div>
  )
}
