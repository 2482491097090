import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import TrackerHero from '../components/tracker/TrackerHero'
import TrackerSection from '../components/tracker/TrackerSection'
import TrackerBlock from '../components/tracker/TrackerBlock'
import TrackerPoints from '../components/tracker/TrackerPoints'
import TrackerAction from '../components/tracker/TrackerAction'
import TrackerMap from '../components/tracker/TrackerMap'
import TrackerTechnicalPoints1 from '../components/tracker/TrackerTechnicalPoints1'
import TrackerCaseStudy from '../components/tracker/TrackerCaseStudy'
import TrackerCaseStudyIntro from '../components/tracker/TrackerCaseStudyIntro'
import TrackerCaseStudyChallenge from '../components/tracker/TrackerCaseStudyChallenge'
import TrackerCaseStudySolution from '../components/tracker/TrackerCaseStudySolution'

import CallToAction from '../components/tracker/CallToAction'

export const query = graphql`
  query BioTracker {
    dataJson {
        trackers {
        biodiversity {
          id
          name
          title
          slug
          icon {
            publicURL
          }
          hero {
            title
            subtitle
            image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
            }
          }
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          block {
            title
            text
          }
          points {
            id
            text
          }
          action {
            title
            subtitle
            points {
              id
              text
            }
          }
          techincalPoints1 {
            title
            points {
              id
              text
            }
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          map {
            publicURL
          }
          caseStudy {
            title
            clientCase1
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            challenge
            solution
          }
        }
      }
    }
  }
  `

export default function BioTracker({ data: { dataJson: { trackers: { biodiversity } } } }) {
  return (
    <Layout title='Biodiversity Tracker' url='/biodiversity-tracker' page='biodiversity-tracker'>
      <TrackerHero hero={biodiversity.hero} title={biodiversity.title} icon={biodiversity.icon} />
      <TrackerSection>
        <TrackerBlock block1={biodiversity.block} />
        <TrackerPoints tracker={biodiversity} />
        <TrackerAction tracker={biodiversity} />
        <TrackerTechnicalPoints1 techincalPoints={biodiversity.techincalPoints1} />
        <TrackerMap tracker={biodiversity} />
      </TrackerSection>
      <TrackerCaseStudy>
        <TrackerCaseStudyIntro tracker={biodiversity} />
        <TrackerCaseStudyChallenge tracker={biodiversity} />
        <TrackerCaseStudySolution tracker={biodiversity} />
      </TrackerCaseStudy>
      <CallToAction />
    </Layout>
  )
}
